import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";

interface AuthorizeUserContextType {
  AuthorizeUserData: any | null;
  fetchAuthorizeUserData: () => void;
  error: string | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  fetchAuthorizeUserById: (id: string) => Promise<void> | null;
}

export const AuthorizeUserContext = createContext<AuthorizeUserContextType>({
  AuthorizeUserData: null,
  setLoading: () => {},
  loading: false,
  error: null,
  fetchAuthorizeUserData: () => {},
  fetchAuthorizeUserById: async() => {},
});

const AuthorizeUserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [AuthorizeUserData, setAuthorizeUserData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchAuthorizeUserData = useCallback(async () => {
    if (!token) {
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/Setting/GetRoles",
        new FormData(),
        {
          headers: {
            "auth-token": token,
          },
          params: {
            token,
          },
        }
      );
      setAuthorizeUserData(response.data);
      // console.log(response.data.Roles)
      setError(null);
    } catch (err: any) {
      console.error("Failed to fetch Authorize Users Data data", err);
      setError(err.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  const fetchAuthorizeUserById = useCallback(
    async (id: string) => {
      const url =
       `https://click.one.sky4system.com/api/user/Setting/Get-RolesById?${id}`;
      const headers: HeadersInit = {};
      const formData = new FormData();
      formData.append("id",id)
      // console.log(id);
      if (token) {
        headers["auth-token"] = token;
      }

      try {
        const response = await axios.post(url, formData, {
          headers: {
            ...(token ? { "auth-token": token } : {}),
          },
          // params: {
          //   token,
          // },
        });

        if (!response) {
          throw new Error(`HTTP error! status: ${response}`);
        }

        const data = await response.data;
        // console.log(data);

        if (data.status === true) {
          // console.log(data);
          // setUserDataId(data.data);
          return data.role;
        } else {
          setError(data.msg || "Failed to fetch the Auth User by ID");
          return null;
        }
      } catch (err) {
        setError("An error occurred while fetching the Auth User by ID");
        return null;
      }
    },
    [token]
  );
  
  useEffect(() => {
    fetchAuthorizeUserData();
  }, [fetchAuthorizeUserData]);

  return (
    <AuthorizeUserContext.Provider
      value={{
        AuthorizeUserData,
        setLoading,
        loading,
        error,
        fetchAuthorizeUserById,
        fetchAuthorizeUserData,
      }}
    >
      {children}
    </AuthorizeUserContext.Provider>
  );
};

export default AuthorizeUserProvider;
