// AuthorizeUsers.tsx
import React, { useContext, useState } from "react";
import { FaSpinner, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FiEdit, FiPlus } from "react-icons/fi";
import { LuRefreshCcw } from "react-icons/lu";
import { AuthorizeUserContext } from "../../../../context/AuthorizeUserContext";
import PermissionsDisplay from "./PermissionsDisplay"; // Adjust the path as needed

interface PermissionDetail {
  [key: string]: number | PermissionDetail | [];
}

interface Permissions {
  [key: string]: PermissionDetail;
}

interface Role {
  id: number;
  name: string;
  is_superadmin: number;
  permissions: Permissions;
  theme_color: string;
  user_master_id: number;
  created_at: string | null;
  updated_at: string | null;
}

const AuthorizeUsers: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const { AuthorizeUserData, fetchAuthorizeUserData, loading } =
    useContext(AuthorizeUserContext);
  // const { userData } = useContext(UserMasterContext);
const navigate = useNavigate()
  const [filters, setFilters] = useState({
    name: "",
    phone: "",
  });

  const sortDevicesByDate = (data: any[]) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.updated_at || a.created_at); // Fallback to created_at if updated_at is null
      const dateB = new Date(b.updated_at || b.created_at);
      return dateB.getTime() - dateA.getTime();
    });
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const applyFilters = (data: any[]) => {
    return data.filter((device) => {
      const matchesName = device.name
        .toLowerCase()
        .includes(filters.name.toLowerCase());

      // You can add more filter conditions here if needed
      return matchesName;
    });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   // Fetch data when component mounts or when fetchAuthorizeUserData changes
  //   fetchAuthorizeUserData();
  // }, [fetchAuthorizeUserData]);

  const sortedUser = sortDevicesByDate(AuthorizeUserData?.Roles ?? []);
  const filteredDevice = applyFilters(sortedUser);
  const totalPages = Math.ceil(filteredDevice.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDevice = filteredDevice.slice(indexOfFirstItem, indexOfLastItem);

  // console.log(currentDevice);

  // const getUserName = (user_master_id: number) => {
  //   if (!userData || !userData.data) {
  //     return "No User Data";
  //   }
  //   const user = userData?.data.find(
  //     (d: { master_user_id: number }) => d.master_user_id === user_master_id
  //   );
  //   // console.log(user)
  //   return user ? user.username : "Unknown user";
  // };

  if (loading) {
    return (
      <div className="text-center py-4">
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
          تحميل...
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 my-4 overflow-auto">
      {/* <TimeNotification /> */}
      <div className="flex justify-start gap-6 px-2 py-2">
        <button
          // onClick={() => setIsOpen(true)} create-userauth
          onClick={() => navigate("/create-userauth")}
          className="flex items-center px-2 py-2 border border-indigo-400 rounded-md text-blue-500 hover:bg-indigo-100 transition"
        >
          <FiPlus className="w-6 h-6 mr-2" />
          اضافة
        </button>
      </div>
      <div className="bg-white rounded-sm shadow-sm max-h-screen hide-scrollbar px-0 overflow-x-auto scrollbar-light">
        {/* Desktop Table */}
        <div className="lg:block" dir="rtl">
          <div className="flex p-2 mx-0">
            <div className="flex flex-col lg:flex-row gap-2 mb-4">
              <input
                type="text"
                name="name"
                placeholder="اسم الصلاحية"
                value={filters.name}
                onChange={handleFilterChange}
                className="p-2 mt-4 border border-gray-400 rounded-md focus:outline-none focus:ring-[1px] focus:ring-gray-400 transition duration-300 ease-in-out transform hover:scale-105"
              />
              <button
                onClick={fetchAuthorizeUserData}
                className={`${loading ? "cursor-not-allowed opacity-50" : ""}`}
                disabled={loading}
                type="button"
              >
                <LuRefreshCcw className="text-blue-500 mt-1.5 mx-4" size={25} />
              </button>
            </div>
          </div>

          <div className="overflow-x-auto scrollbar-light">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    صلاحية المستخدم
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    صلاحيات الشاشات
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    تاريخ الانشاء
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    عمليات
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentDevice?.map((author: Role) => (
                  <tr
                    key={author.id}
                    className={`border-l-4 ${
                      author.theme_color === "skin-red"
                        ? "border-red-500"
                        : "border-blue-500"
                    }`}
                  >
                    <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-700 font-semibold">
                      <span className="ml-2 text-sm text-yellow-500">
                        {author.name}
                      </span>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-700">
                      <PermissionsDisplay permissions={author.permissions} />
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-700">
                      {author.created_at
                        ? new Date(author.created_at).toLocaleDateString(
                            "ar-EG"
                          )
                        : "لم يتم التحديد"}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-center text-xs text-gray-700">
                      <Link
                        title={`${author.name}`}
                        className="text-blue-400 hover:text-blue-600 transition"
                        to={`/authorize-user/${author.id}`}
                      >
                        <FiEdit size={20} />
                      </Link>
                    </td>
                  </tr>
                ))}
                {currentDevice.length === 0 && (
                  <tr>
                    <td
                      colSpan={4}
                      className="px-4 py-2 text-center text-gray-500"
                    >
                      لا توجد بيانات لعرضها.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center p-4">
            <div className="flex items-center gap-2">
              <label htmlFor="itemsPerPage" className="text-gray-500">
                الصفوف بكل صفحة:
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className="text-sm text-gray-900 border focus:outline-none border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-300 focus:border-gray-300 p-1 dark:focus:border-gray-400"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={filteredDevice.length}>الكل</option>
              </select>
            </div>
            <div className="flex justify-center gap-4 items-center">
              <button
                onClick={handlePreviousPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={currentPage === 1}
              >
                <FaArrowLeft />
              </button>
              <span className="text-gray-500">
                صفحة {currentPage} من {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={currentPage === totalPages}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>
          {/* End of Pagination */}
        </div>
      </div>
    </div>
  );
};

export default AuthorizeUsers;
