import { AiFillContacts } from "react-icons/ai";
import GroupContact from "./GroupContact";

const GroupContactComponent: React.FC = () => {
  return (
    <div className="justify-start gap-3 container rounded-md">
      <div className="flex justify-start mx-2 text-lg mt-8">
        <div className="flex justify-start gap-2 m-4 mx-8 rounded-md text-blue-500 font-bold p-1">
        <AiFillContacts size={25} className="text-cyan-600" />
        <span>جهات الاتصال</span>
        </div>
      </div>
      <GroupContact />
    </div>
  );
};

export default GroupContactComponent;
