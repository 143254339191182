import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import axios from "axios";
import DeviceSelect from "../data/DeviceSelect";
import { GroupContactContext } from "../../../../context/contactsgroups/GroupContactContext";
import { FiCheck, FiPlus, FiSearch } from "react-icons/fi";
import { FaArrowLeft, FaArrowRight, FaTimes } from "react-icons/fa";
import { IoMdArrowForward } from "react-icons/io";
import { DeviceMasterContext } from "../../../../context/devices/DeviceMasterContext";
import { MdOutlineDelete } from "react-icons/md";

// import ContactSelect from "../data/ContactsSelect";

type Contact = {
  name: string;
  phone: string;
};

interface SendResult {
  status: "success" | "error";
  message: string;
}

interface GroupContact {
  id: number;
  group_id: number;
  phone_name: string;
  device_id: string;
  contact_id: number;
  group_name: string;
}

const CreateContactsId: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false); // State to toggle dialog visibility

  const closeDialog = () => {
    setIsOpen(false); // Close dialog function
  };

  const { id } = useParams<{ id: string }>();
  const { token } = useContext(AuthContext);

  const context = useContext(GroupContactContext);

  if (!context) {
    throw new Error("useContext must be used within a ScheduleProvider");
  }
  const { fetchGroups } = context;

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkedContacts, setCheckedContacts] = useState<string[]>([]);

  // Store selected phone numbers

  const [contacts, setContacts] = useState<Contact[]>([
    { name: "", phone: "" },
  ]);

  // const phoneObjects = contacts.map((contact) => ({ phone: contact.phone }));

  const [messageError, setMessageError] = useState<string | null>(null);
  const [deviceError, setDeviceError] = useState<string | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [sendResults, setSendResults] = useState<SendResult[]>([]);

  const [filterText, setFilterText] = useState<string>("");

  // const [selectedContact, setSelectedContact] = useState<any>(null);

  // const navigate = useNavigate();

  const clearMessages = () => setSendResults([]);

  const resetErrors = () => {
    setMessageError(null);
    setDeviceError(null);
  };

  const { loading, groupcon, groupconid, setLoadingS, fetchGroupById } =
    context;
  const { deviceData } = useContext(DeviceMasterContext);

  // console.log(deviceData)

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Map contact_id values to strings
    const allContactIds = currentgroupcon?.map((contact) =>
      contact?.contact_id.toString()
    );

    if (event.target.checked) {
      setCheckedContacts(allContactIds || []);
    } else {
      setCheckedContacts([]);
    }
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    phoneNumber: string
  ) => {
    if (event.target.checked) {
      // Add contact to checkedContacts if it is checked
      setCheckedContacts([...checkedContacts, phoneNumber]);
    } else {
      // Remove contact from checkedContacts if it is unchecked
      setCheckedContacts(
        checkedContacts.filter((number) => number !== phoneNumber)
      );
    }
  };

  // const handleCheckboxChange = (phone: string) => {
  //   setSelectedContacts((prev) =>
  //     prev.includes(phone)
  //       ? prev.filter((selectedPhone) => selectedPhone !== phone)
  //       : [...prev, phone]
  //   );
  // };

  const validateInputs = (): boolean => {
    if (!selectedDevice) {
      setDeviceError("يجب اختيار جهاز متصل.");
      return false;
    }

    if (contacts.some((contact) => !contact.name || !contact.phone)) {
      setMessageError("الاسم ورقم الهاتف مطلوبان.");
      return false;
    }

    return true;
  };

  const handleAddRow = () => {
    setContacts([...contacts, { name: "", phone: "" }]);
  };

  const handleRemoveRow = (index: number) => {
    setContacts(contacts.filter((_, i) => i !== index));
  };

  const handleInputChange = (
    index: number,
    field: keyof Contact,
    value: string
  ) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const groupDeviceId = groupcon?.groups[0]?.device_id;

  // Find the matching device in deviceData
  const matchingDevice = deviceData?.data?.find(
    (device: any) => device.deviceId === groupDeviceId
  );

  // Get the Token from the matching device (if found)
  const devicesToken = matchingDevice?.Token;

  // console.log(devicesToken)
  // console.log(`devices_Token: ${selectedDevice?.device_id}`)

  const handleCreateGroup = async () => {
    resetErrors();
    if (!validateInputs()) return;
    setIsLoading(true);

    let formData = {
      group_id: `${id}`,
      MobileData: contacts,
    };

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/storeGroupContact",
        formData,
        {
          headers: {
            "auth-token": token,
          },
          params: {
            deviceId: selectedDevice.device_id,
            deviceToken: devicesToken,
          },
        }
      );
      // console.log(selectedDevice.device_id)
      // console.log(response.data);
      // console.log(devicesToken);

      const result = await getSendResult(response.data);
      setSendResults((prevResults) => [
        ...prevResults,
        {
          status: result.sendResult.status,
          message: result.sendResult.message,
        },
      ]);

      if (response.data.status === true) {
        fetchGroups();
        const timeout = setTimeout(() => {
          // navigate("/groupcontact");
          setIsOpen(false);
        }, 3000);
        return () => clearTimeout(timeout);
      } else {
        setTimeout(() => {
          clearMessages();
        }, 3000);
      }
    } catch (error) {
      console.log("internal server error 500:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleDeleteGroup = async () => {
    resetErrors();
    if (!validateInputs()) return;
    setIsLoading(true);
    const checkedContactsPhone = checkedContacts;
    const formattedContacts = checkedContactsPhone.map(phone => ({ phone }));
    let formData = {
      group_id: `${id}`,
      MobileData: formattedContacts,
    };

    try {
      const response = await axios.post(
        "https://click.one.sky4system.com/api/user/Delete-GroupContact",
        formData,
        {
          headers: {
            "auth-token": token,
          },
          params: {
            deviceId: selectedDevice.device_id,
            deviceToken: devicesToken,
          },
        }
      );
      // console.log(selectedDevice.device_id)
      console.log(response.data);
      // console.log(formattedContacts);
      // console.log(devicesToken);

      const result = await getSendResult(response.data);
      setSendResults((prevResults) => [
        ...prevResults,
        {
          status: result.sendResult.status,
          message: result.sendResult.message,
        },
      ]);

      if (response.data.status === true) {
        fetchGroups();
        const timeout = setTimeout(() => {
          // navigate("/groupcontact");
          setIsOpen(false);
        }, 3000);
        return () => clearTimeout(timeout);
      } else {
        setTimeout(() => {
          clearMessages();
        }, 3000);
      }
    } catch (error) {
      console.log("internal server error 500:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSendResult = async (data: any) => {
    let result: SendResult;
    let successSend: { text: string; type: "success" | "error" };

    if (data.status === true) {
      result = { status: "success", message: `تم إنشاء مجموعة جهات اتصال` };
      successSend = { text: `تم إنشاء مجموعة جهات اتصال`, type: "success" };
    } else if (data.errNum === "") {
      result = { status: "error", message: `يجب إضافة حقول الادخال` };
      successSend = { text: `يجب إضافة حقول الادخال `, type: "error" };
    } else {
      result = { status: "error", message: `فشل في إنشاء مجموعة جهات اتصال` };
      successSend = { text: `فشل في إنشاء مجموعة جهات اتصال`, type: "error" };
    }

    return { sendResult: result, successSend };
  };

  const indexOfLastRow = currentPage * rowsPerPage;

  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const filteredGroups =
    groupconid?.groupcontacts?.filter((group) =>
      group.phone_name.toLowerCase().includes(filterText.toLowerCase())
    ) || [];
  const currentgroupcon = filteredGroups.slice(indexOfFirstRow, indexOfLastRow);

  useEffect(() => {
    const fetchData = async () => {
      if (fetchGroupById && id) {
        setLoadingS(true);
        try {
          const contactGroupId = await fetchGroupById(id);

          // console.log("contactGroupId:", contactGroupId)
          // console.log("groupcon:", groupcon?.groups[0] );

          setSelectedDevice(groupcon?.groups[0] || null);

          if (contactGroupId) {
            const { groupcontacts } = contactGroupId;

            if (Array.isArray(groupcontacts)) {
              const contactIds = (groupcontacts as GroupContact[]).map(
                (contact: any) => ({
                  name: contact.phone_name,
                  phone: contact.contact_id,
                })
              );

              setContacts(contactIds);
            } else {
              setContacts([]);
            }
          } else {
            console.log("No contact data received");
          }
        } catch (error) {
          console.error("Error fetching contact data:", error);
        } finally {
          setLoadingS(false);
        }
      }
    };

    fetchData();
  }, [id, fetchGroupById, setLoadingS, groupcon?.groups]);

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setRowsPerPage(value);
    setCurrentPage(1);
  };
  // Calculate total pages
  const totalPages = Math.ceil(
    (groupconid?.groupcontacts?.length || 0) / rowsPerPage
  );

  // Here you can send the selected phone numbers

  // Loading state
  if (loading) {
    return (
      <div className="max:w-7xl border border-gray-200 px-16 rounded-3xl p-2 grid justify-center py-12 mx-2 sm:mx-4">
        <div className="flex justify-between px-4 py-2">
          <div className="max:w-6xl py-12 px-16">
            Loading groups contacts...
          </div>
        </div>
      </div>
    );
  }
  // console.log("Selected Phone Numbers:", checkedContacts);
  // console.log(checkedContacts);

  return (
    <div dir="rtl" className="mt-12 p-2">
      <div className="flex justify-start gap-6 px-2 py-2">
        <button
          onClick={() => setIsOpen(true)}
          className="flex items-center px-2 py-2 border border-indigo-400 rounded-md text-blue-500"
        >
          <FiPlus className="w-6 h-6 mr-2" />
          اضافة
        </button>
      </div>

      <div>
        <div className="my-3 flex gap-2 px-2">
          <div className="relative inline-block">
            <FiSearch
              className="absolute top-3 left-3 text-gray-500"
              size={20}
            />
            <input
              type="text"
              placeholder="بحث..."
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className="pl-10 p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>
        </div>
        <Link to={"/groupcontact"} className="flex-1">
          <button>
            <IoMdArrowForward className="w-6 h-6 mr-2" />
          </button>
        </Link>
        <div className="sm:flex gap-2 px-2 grid justify-start py-2 mx-2 sm:mx-4 space-y-1"></div>
        <table className="w-[99%] bg-white">
          <thead className="items-start">
            {checkedContacts?.length <= 0 ? (
              <tr className="hidden"></tr>
            ) : (
              <tr>
                <th className="px-3 py-2 flex items-center gap-2  border-b-[1px] border-gray-200">
                  <button
                    onClick={handleDeleteGroup}
                    disabled={isLoading}
                    className={`flex rounded-full hover:bg-red-50 py-2 px-1 ${
                      isLoading ? "cursor-not-allowed opacity-50" : ""
                    }`}
                  >
                    <MdOutlineDelete className="text-red-500" size={25} />
                    <span className="inline-flex mt-0 font-semibold text-blue-600">
                      {checkedContacts?.length}
                    </span>
                  </button>
                </th>
              </tr>
            )}

            <tr>
              <th className="px-4 py-2 flex items-center gap-2">
                <div className="relative inline-block">
                  <input
                    type="checkbox"
                    checked={checkedContacts.length === contacts.length}
                    onChange={handleSelectAll}
                    className="form-checkbox h-5 w-5 mt-0.5 appearance-none outline-none bg-transparent rounded-md border-2 border-gray-500 checked:bg-blue-500 checked:border-transparent"
                  />
                  <FiCheck
                    className="absolute top-0 left-0 mt-0.5 pointer-events-none text-white"
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
                <span className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider">
                  الاسم
                </span>
              </th>
              <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
                رقم الهاتف
              </th>
              {/* <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              operations
            </th> */}
            </tr>
          </thead>

          <tbody dir="rtl" className="overflow-y-auto scrollbar-light">
            {currentgroupcon.map((group) => (
              <tr key={group.id}>
                <td className="border-t text-xs border-gray-200 px-4 py-2 flex gap-2 items-center">
                  <div className="relative inline-block">
                    <input
                      checked={checkedContacts.includes(
                        group.contact_id.toString()
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(e, group.contact_id.toString())
                      }
                      type="checkbox"
                      className="form-checkbox h-5 w-5 mt-0.5 appearance-none outline-none bg-transparent rounded-md border-2 border-gray-500 checked:bg-blue-500 checked:border-transparent"
                    />
                    <FiCheck
                      className="absolute top-0 left-0 mt-0.5 pointer-events-none text-white"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </div>
                  <strong>{group.phone_name}</strong>
                  {/* Display group phone name */}
                </td>

                <td className="border-t text-xs border-gray-200 px-2 py-2">
                  {group.contact_id} {/* Group-specific contact ID */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination controls */}
        <div className="flex justify-start items-center gap-4 px-2 py-2">
          <div>
            {" "}
            {/* Rows per page selection */}
            <select
              value={rowsPerPage}
              onChange={handleItemsPerPageChange}
              className="text-sm text-gray-900 border focus:outline-none border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-300 focus:border-gray-300 p-1 dark:focus:border-gray-400"
            >
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
            </select>
          </div>
          <div className="flex p-2 justify-center gap-4 items-center ">
            <button
              onClick={() =>
                setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
              }
              disabled={currentPage === 1}
              className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
            >
              <FaArrowLeft />
            </button>
            <span className="self-center text-gray-700">
              <span className="text-gray-500">
                  صفحة {currentPage} من {totalPages}
                </span>
            </span>
            <button
              onClick={() =>
                setCurrentPage(
                  currentPage < totalPages ? currentPage + 1 : totalPages
                )
              }
              disabled={currentPage === totalPages}
              className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
            >
              <FaArrowRight />
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="fixed inset-0 z-50 hide-scrollbar bg-white overflow-auto p-4 mt-12 py-12 mx-2">
          {/* Close button */}
          <button
            onClick={closeDialog}
            className="absolute top-4 right-4 p-1 px-3 text-red-500 font-semibold rounded-full"
          >
            <FaTimes size={20} />
          </button>
          <div className="max:w-7xl sm:mx-28 border border-gray-200 px-4 rounded-3xl p-2 justify-center py-12 mx-auto">
            {sendResults.length > 0 && (
              <div className="">
                {sendResults?.map((result, index) => (
                  <div
                    key={index}
                    className={`absolute top-16 right-8 flex gap-3 w-auto p-2  ${
                      result.status === "error" ? "bg-red-500" : "bg-green-600"
                    } text-white text-sm rounded-lg shadow-lg z-50 opacity-75`}
                  >
                    <button
                      onClick={clearMessages}
                      className="cursor-pointer mt-1"
                    >
                      <span className="p-1 text-lg text-gray-100">X</span>
                    </button>

                    <div className="mt-2">
                      <p
                        className={`${
                          result.status === "error"
                            ? "text-white"
                            : "text-white"
                        }`}
                      >
                        {result.message}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="mb-6 hidden">
              <DeviceSelect onDeviceSelect={setSelectedDevice} />
              {deviceError && (
                <p className="text-red-500 text-sm">{deviceError}</p>
              )}
            </div>

            <div>
              {contacts.map((contact, index) => (
                <div key={index} className="my-4">
                  <input
                    type="text"
                    placeholder="الاسم"
                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    value={contact.name}
                    onChange={(e) =>
                      handleInputChange(index, "name", e.target.value)
                    }
                  />
                  <input
                    type="text"
                    placeholder="رقم الهاتف"
                    className="w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    value={contact.phone}
                    onChange={(e) =>
                      handleInputChange(index, "phone", e.target.value)
                    }
                  />
                  {index > 0 && (
                    <button
                      type="button"
                      className="mt-2 text-red-500"
                      onClick={() => handleRemoveRow(index)}
                    >
                      حذف الصف
                    </button>
                  )}
                </div>
              ))}
              {/* <div className="mb-4">
          <ContactSelect
            onContactSelect={setSelectedContact}
            selectedOptions={selectedContact}
            selectedDeviceId={selectedDevice?.deviceId}
          />
        </div> */}
              <button
                type="button"
                className="my-4 text-blue-500"
                onClick={handleAddRow}
              >
                إضافة صف جديد
              </button>
            </div>

            {messageError && (
              <p className="text-red-500 text-sm">{messageError}</p>
            )}

            <button
              type="button"
              className={`justify-end gap-2 px-6 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition ${
                isLoading ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={handleCreateGroup}
              disabled={
                isLoading &&
                contacts.some((contact) => !contact.name || !contact.phone)
              }
            >
              {isLoading ? "إنشاء..." : "إنشاء"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateContactsId;
