// src/Header.tsx
import React, { useContext, useState, useRef } from "react";
import { FaUser, FaSignOutAlt } from "react-icons/fa";
import { IoIosMenu } from "react-icons/io";
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";


const getInitials = (name: string | null) => {
  if (!name) return "";
  const nameParts = name.split(" ");
  const initials = nameParts.map((part) => part.charAt(0)).join("");
  return initials.toUpperCase();
};

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) =>{
  const { usernamee } = useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleMouseEnter = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      setDropdownOpen(false);
    }, 300); 
  };

  return (
    <div className="sticky top-0 z-999 w-full border-b-[1px] border-gray-200 bg-slate-50 flex justify-between mb-8 items-center">
      <div className="flex items-center">
          {/* <!-- Hamburger Toggle BTN --> */}
          <button aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }} className="p-2 pl-4">
          {!props.sidebarOpen ? (
            <IoIosMenu className="w-8 h-8 bg-slate-100 rounded-md" />
          ) : (
            <IoIosMenu className="w-8 h-8 bg-slate-100 rounded-md" />
          )}
        </button>
          {/* <!-- Hamburger Toggle BTN --> */}

          {/* <Link className="block flex-shrink-0 lg:hidden" to="/">
            <img src={LogoIcon} alt="Logo" className="tallOrWideAndLandscape:hidden tallOrWideAndPortrait:hidden" />
          </Link> */}

    
      </div>
      <div className="App">
        <div
          className="relative"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <button
            onClick={toggleDropdown}
            className="flex items-center mx-5 focus:outline-none"
          >
            <span className="mr-4 bg-indigo-500 font-semibold text-white rounded-full w-8 h-8 flex items-center justify-center">
              {getInitials(usernamee)}
            </span>
          </button>
          {dropdownOpen && (
            <div
              className="absolute left-0 mt-2 w-56 bg-gray-100 border border-gray-200 rounded-md shadow-lg z-20"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="py-1">
                <Link
                  to="/editprofile"
                  className="px-4 py-3 text-gray-500 hover:bg-gray-100 flex items-center"
                >
                  <FaUser className="mx-2" /> {usernamee}
                </Link>
                <hr className="mx-2 text-gray-300" />
                <Link
                  onClick={handleLogout}
                  to="/logout"
                  className="px-4 py-3 text-gray-500 hover:bg-gray-100 flex items-center"
                >
                  <FaSignOutAlt className="mx-2" /> تسجيل الخروج
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
