import React, {
    createContext,
    useState,
    useEffect,
    ReactNode,
    useContext,
    useCallback,
  } from "react";
  import { AuthContext } from "../AuthContext";
  import axios from "axios";
  
  // GroupContact interface
  interface GroupContact {
    id: number;
    group_id: number;
    phone_name: string;
    device_id: string;
    contact_id: number;
    group_name: string;
  }

  // Groups interface
  interface Groups {
    id: number;
    name: string;
    user_id: number;
    group_id: string;
    device_id: string;
    groupcontacts_count: number;
  }
  
  // GroupData interface
  interface GroupData {
    groups: Groups[];
    total_groups: number;
  }

  interface GroupDataId {
    groupcontacts: GroupContact[];
  }
  
  // Context type interface
  interface GroupContactContextType {
    groupcon: GroupData | null;
    groupconid: GroupDataId | null;
    loading: boolean;
    error: string | null;
    fetchGroups: () => Promise<void>;
    setLoadingS: React.Dispatch<React.SetStateAction<boolean>>;
    fetchGroupById: (id: string) => Promise<void> | null;
  }
  
  // Provider props interface
  interface GroupProviderProps {
    children: ReactNode;
  }
  
  // Create the context
  export const GroupContactContext = createContext<GroupContactContextType | undefined>(
    undefined
  );
  
  const GroupProvider: React.FC<GroupProviderProps> = ({ children }) => {
    const [groupcon, setgroupcon] = useState<GroupData | null>(null);
    const [groupconid, setgroupconid] = useState<GroupDataId | null>(null);
    const [loading, setLoadingS] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { token } = useContext(AuthContext);
  
    const fetchGroups = useCallback(async () => {
      const url = "https://click.one.sky4system.com/api/user/Get-GroupContact";
  
      const formData = new FormData();
      const headers: HeadersInit = {};
  
      if (token) {
        headers["auth-token"] = token;
      }
  
      try {
        const response = await axios.post(url, formData, {
          headers: {
            ...(token ? { "auth-token": token } : {}),
          },
          params: {
            token,
          },
        });
        if (!response) {
          throw new Error(`HTTP error! status: ${response}`);
        }
  
        const data = await response.data;
        // console.log(response.status)
        if (data.status === true) {
          setgroupcon(data.data);
        } else {
          setError(data.msg || "Failed to fetch groupcon");
        }
      } catch (err) {
        setError("An error occurred while fetching, Internal Server Error 500.");
      } finally {
        setLoadingS(false);
      }
    }, [token]);
  
    const fetchGroupById = useCallback(
      async (id: string) => {
        const url = "https://click.one.sky4system.com/api/user/Get-GroupContact-byid";
        const headers: HeadersInit = {};
        const formData = new FormData();
        formData.append("id", id);
    
        if (token) {
          headers["auth-token"] = token;
        }
    
        try {
          const response = await axios.post(url, formData, {
            headers: {
              ...(token ? { "auth-token": token } : {}),
            },
            params: {
              token,
            },
          });
    
          if (!response) {
            throw new Error(`HTTP error! status: ${response}`);
          }
    
          const data = await response.data;
          // console.log(data);
    
          if (data.status === true && data.data.length > 0) {
            // console.log(data);
            setgroupconid(data.data[0]); 
            return data.data[0];
          } else {
            setError(data.msg || "Failed to fetch the Group by ID");
            return null; 
          }
        } catch (err) {
          setError("An error occurred while fetching, Internal Server Error 500.");
          return null; 
        }
      },
      [token]
    );

    useEffect(() => {
      fetchGroups();
    }, [fetchGroups]);
  
    return (
      <GroupContactContext.Provider
        value={{
          groupcon,
          groupconid,
          loading,
          error,
          fetchGroups,
          fetchGroupById,
          setLoadingS,
        }}
      >
        {children}
      </GroupContactContext.Provider>
    );
  };
  
  export default GroupProvider;
  