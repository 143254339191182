import { AiFillFileText } from "react-icons/ai";
import CreateCompainSMS from "./CreateCompainSMS";

const ComainMessagecomponent: React.FC = () => {
  return (
    <div
      dir="ltr"
      className="border-b h-screen justify-start gap-3 container rounded-md"
    >
      <div className="flex justify-end mx-8 text-lg mt-8">
        <span>ارسال رسائل مجدولة</span>
        <AiFillFileText size={25} className="text-cyan-600" />
      </div>
      <CreateCompainSMS />
    </div>
  );
};

export default ComainMessagecomponent;
