// src/components/TextGroupComponent.tsx
import React from "react";
import { AiFillFileText } from "react-icons/ai";
import RichTextEditorGroup from "./RichTextEditorGroup";

const TextGroupComponent: React.FC = () => {
  return (
    <div className="border-b border-gray-300 justify-start  gap-3 container rounded-md">
      <div className="flex justify-start mx-16 text-lg mt-8">
        <AiFillFileText size={25} className="text-cyan-600" />
        <span>ارسال نص للمجموعات</span>
      </div>

      <RichTextEditorGroup />
    </div>
  );
};

export default TextGroupComponent;
