import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar";
import Header from "../Header";

const Layout: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleResize = () => {
    if (window.innerWidth >= 1024) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div dir="rtl" className="h-screen overflow-y-auto scrollbar-light selection:bg-red-100">
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* <main
        className={`flex-1 pb-0 bg-white hide-scrollbar transition-all duration-300 ease-in-out ${
          sidebarOpen ? "bg-black" : "opacity-100"
        } ${sidebarOpen ? "ml-1 sm:ml-64 " : "ml-0 sm:ml-16 "} `}
      >
        <Outlet />
      </main> */}
      <main
        className={`flex-1 pb-0 bg-white hide-scrollbar transition-all duration-300 ease-in-out ${
          sidebarOpen ? "bg-black" : "opacity-100"
        } ${sidebarOpen ? "mr-2 sm:mr-64 " : "mr-0 sm:mr-16 "} `}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
