import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
// import { format } from "date-fns";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { Value as DateTimePickerValue } from "react-datetime-picker/dist/cjs/shared/types";
import { FiCalendar } from "react-icons/fi";

interface TimePickerProps {
  initialValue?: DateTimePickerValue;
  onChange: (value: DateTimePickerValue) => void;
}

const TimePickerComponent: React.FC<TimePickerProps> = ({
  initialValue = new Date(),
  onChange,
}) => {
  const [value, setValue] = useState<DateTimePickerValue>(initialValue || null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (newValue: DateTimePickerValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div
      id="datetime-picker"
    >
      <DateTimePicker
        onChange={handleChange}
        value={value}
        calendarIcon={<FiCalendar className="hover:text-blue-500" size={22}/>}
        dayPlaceholder="dd"
        autoFocus={false}
        className="react-datetime-picker w-full text-slate-600 appearance-none py-0.5 outline-none focus:bg-white"
      />
    </div>
  );
};

export default TimePickerComponent;
