// src/components/TextComponent.tsx
import React from "react";
import RichTextEditor from "./RichTextEditor";
import { AiFillFileText } from "react-icons/ai";

const TextComponent: React.FC = () => {
  return (
    <div className="justify-end h-screen gap-3 container pt-8">
      <div className="flex justify-start mx-16 text-lg">
        <AiFillFileText size={25} className="text-cyan-600" />
        <span>ارسال رسائل نصية</span>
      </div>

      <RichTextEditor />
    </div>
  );
};

export default TextComponent;
