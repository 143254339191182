import axios from 'axios';
import React from 'react';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

export const handleDeleteDevice = async (
selectedDevice: any, token: string | null, // token: string,
fetchDeviceMaster: () => void, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, setNotification: React.Dispatch<
  React.SetStateAction<{ message: string; type: 'success' | 'error'; } | null>
>, setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>, setSelectedDevice: React.Dispatch<React.SetStateAction<any | null>>) => {
  if (selectedDevice) {
    setIsLoading(true);
    setNotification(null);
    try {
      const response = await axios.post(
        'https://click.one.sky4system.com/api/user/DeleteDevice',
        {
          deviceToken: selectedDevice.Token,
          deviceId: selectedDevice.deviceId,
        },
        {
          headers: {
            'auth-token': token,
          },
        }
      ); 
      if (response.data.status === true) {
        setNotification({ message: 'تم حذف الجهاز', type: 'success' });
        fetchDeviceMaster();
      } else {
        setNotification({ message: 'فشل في حذف الجهاز', type: 'error' });
      }
    } catch (error) {
      setNotification({ message: 'حدث خطأ! حاول مرة أخرى', type: 'error' });
    } finally {
      setIsLoading(false);
      setIsDialogOpen(false);
      setSelectedDevice(null);
    }
  }
};

export const handleSort = (
  column: string,
  sortColumn: string,
  sortDirection: string,
  setSortColumn: React.Dispatch<React.SetStateAction<string>>,
  setSortDirection: React.Dispatch<React.SetStateAction<string>>
) => {
  if (sortColumn === column) {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  } else {
    setSortColumn(column);
    setSortDirection('asc');
  }
};

export const sortDevices = (data: any[], sortColumn: string, sortDirection: string) => {
  return data.sort((a, b) => {
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    if (sortColumn === 'updated_at') {
      return sortDirection === 'asc'
        ? new Date(valueA).getTime() - new Date(valueB).getTime()
        : new Date(valueB).getTime() - new Date(valueA).getTime();
    }

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return sortDirection === 'asc'
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return 0;
  });
};

export const getSortIcon = (column: string, sortColumn: string, sortDirection: string) => {
  if (sortColumn !== column) {
    return (
      <span className="hover:block cursor-pointer">
        <FaSort />
      </span>
    );
  } else if (sortDirection === 'asc') {
    return (
      <span className="hover:block cursor-pointer">
        <FaSortUp />
      </span>
    );
  } else {
    return (
      <span className="hover:block cursor-pointer">
        <FaSortDown />
      </span>
    );
  }
};
