import React, { useContext } from "react";
import Card from "./Cart";
// import { UserMasterContext } from "../../../../context/UserMasterContext";
import { MessageApiContext } from "../../../../context/messages/MessageApiContext";
import { TbMessageCheck } from "react-icons/tb";
import { TbMessage2X } from "react-icons/tb";
import { TbMessage } from "react-icons/tb";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { ScheduleContext } from "../../../../context/messages/ScheduleContext";
import { Link } from "react-router-dom";

const CartList: React.FC = () => {
  // const { userData } = useContext(UserMasterContext);
  const context = useContext(MessageApiContext);
  const contexts = useContext(ScheduleContext);

  if (!context) {
    throw new Error(
      "MessageApiContext must be used within a MessageApiProvider"
    );
  }
  const { MessagesData } = context;
  if (!contexts) {
    throw new Error("useContext must be used within a ScheduleProvider");
  }

  const { scheduled } = contexts;
  // console.log("schedules data", MessagesData);

  return (
    <div className="justify-center py-3 mt-2 mx-4 px-2">
      <div className="grid grid-cols-1 bg md:grid-cols-2 min-[350px]:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        {/* {rolePrivileges === 3 ? (
          <Link to="/updatepricing">
            <Card
              icon={<AiOutlineShoppingCart size={30} />}
              value={planData && planData.data ? planData.data.length : 0}
              label="عروض الاسعار"
            />{" "}
          </Link>
        ) : (
          <Link to="/dashboard">
            <Card
              icon={<AiOutlineUser size={30} />}
              value={userData && userData.data ? userData.data.length : 0}
              label="عدد المستخدمين"
            />
          </Link>
        )} */}
        {/* {rolePrivileges === 3 ? (
          <Link to="/updatepromotions">
            <Card
              icon={<GiPresent size={30} />}
              value={orderData && orderData.data ? orderData.data.length : 0}
              label="تجديد الباقات"
            />
          </Link>
        ) : (
          rolePrivileges === 2 && (
            <Link
              className="border border-gray-100 font-bold shadow-sm text-blue-500"
              to="/updatepromotions"
            >
              <Card
                icon={<AiOutlineShoppingCart size={30} />}
                value="$0"
                label="الدفع"
              />
            </Link>
          )
        )} */}
        <Link to="/reports/success-message">
          <Card
            icon={<TbMessageCheck size={30} />}
            value={MessagesData?.DeliveredMessege || "0"}
            label="الرسائل المرسلة"
          />
        </Link>
        <Link to="/reports/failure-message">
          <Card
            icon={<TbMessage2X size={30} />}
            value={MessagesData?.failedMessege || "0"}
            label="الرسائل الفاشلة"
          />
        </Link>
        <Link to="/reports/total-message">
          <Card
            icon={<TbMessage size={30} />}
            value={MessagesData?.totalMessege || "0"}
            label="اجمالي عدد الرسائل"
          />
        </Link>
        <Link to="/reports/total-shcedual">
          <Card
            icon={<RiCalendarScheduleLine size={30} />}
            value={scheduled?.totalSchedule || "0"}
            label="الرسائل المرسلة حسب التاريخ(الجدولة)"
          />
        </Link>
      </div>
    </div>
  );
};

export default CartList;
