import React, { useContext, useEffect, useState } from "react";
import Card from "./Cart";
import {
  AiOutlineDesktop,
  AiFillContacts,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { ContactContext } from "../../context/contactsgroups/ContactContext";
import { DashbordContext } from "../../context/DashbordContext";
import { MessageApiContext } from "../../context/messages/MessageApiContext";
import { TbMessage, TbMessage2X, TbMessageCheck } from "react-icons/tb";
import { RiCalendarScheduleLine, RiWhatsappFill } from "react-icons/ri";
import TimePickerComponentGrid from "../../pages/dashboard/messages/campaign/TimePickerComponentGrid";
import { CiFilter } from "react-icons/ci";
import { Link } from "react-router-dom";

const CartList: React.FC = () => {
  const { ContactsData } = useContext(ContactContext);
  const { dashboardData, fetchDashbordData, loading } =
    useContext(DashbordContext);
  const context = useContext(MessageApiContext);
  const [localFilters, setLocalFilters] = useState({
    status: "",
    type: "",
    to_number: "",
    from_date: "",
    to_date: "",
    Search: "",
  });
  if (!context) {
    throw new Error(
      "MessageApiContext must be used within a MessageApiProvider"
    );
  }

  const { Messages, loadingm, setFilters, filters } = context;

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);
  // console.log(Messages);

  const applyFilters = () => {
    const updatedFilters: any = {};

    if (localFilters.status !== "") {
      updatedFilters.status = localFilters.status;
    }

    if (localFilters.type) {
      updatedFilters.type = localFilters.type;
    }

    if (localFilters.to_number) {
      updatedFilters.to_number = localFilters.to_number;
    }

    if (localFilters.from_date) {
      updatedFilters.from_date = localFilters.from_date;
    }

    if (localFilters.to_date) {
      updatedFilters.to_date = localFilters.to_date;
    }

    if (localFilters.Search) {
      updatedFilters.Search = localFilters.Search;
    }

    setFilters(updatedFilters);
    fetchDashbordData();
  };

  const scheduledMessagesCount = Messages
    ? Messages.filter(
        (message: any) => message.type === "schedule-message"
      ).length
    : 0;
  const successMessagesCount = Messages
    ? Messages.filter((message: any) => message.status === "pending").length
    : 0;
  const failMessagesCount = Messages
    ? Messages.filter((message: any) => message.status === "fail").length
    : 0;

  if (loading) {
    return (
      <div className="max:w-7xl border border-gray-200 px-16 mx-4 rounded-3xl p-2 grid justify-center py-12 sm:mx-4">
        <div className="flex justify-between px-4 py-2">
          <div className="max:w-6xl py-12 px-16">انتظار التحميل...</div>
        </div>
      </div>
    );
  }
  return (
    <div className="justify-center py-3 mt-8 mx-4 px-2">
      <div className="grid grid-cols-1 bg md:grid-cols-2 min-[350px]:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <Card
          icon={<RiWhatsappFill size={30} />}
          value={
            dashboardData && dashboardData.data
              ? dashboardData.data.messagesCount
              : 0
          }
          label="اجمالي الرسائل"
        />

        <Card
          icon={<AiFillContacts size={30} />}
          value={
            ContactsData && Array.isArray(ContactsData)
              ? ContactsData.reduce((totalCount, deviceContacts) => {
                  // Ensure `data` exists and is an array before summing its length
                  const contactsArray = deviceContacts?.data?.data;
                  return (
                    totalCount +
                    (Array.isArray(contactsArray) ? contactsArray.length : 0)
                  );
                }, 0)
              : 0
          }
          label="جهات الإتصال"
        />
        <Card
          icon={<AiOutlineDesktop size={30} />}
          value={
            dashboardData && dashboardData.data
              ? dashboardData.data.devicesCount
              : 0
          }
          label="عدد الأجهزة"
        />
      </div>
      <div className="mb-2 mt-12 p-2 mx-3 bg-slate-100 border border-gray-300 rounded-lg">
        {/* <h2 className="text-xl font-semibold mb-4">Filter Messages</h2> */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Status Filter */}

          {/* <div>
            <label htmlFor="status" className="block mb-0.5">
              الحالة:
            </label>
            <select
              id="status"
              value={localFilters.status}
              onChange={(e) =>
                setLocalFilters({ ...localFilters, status: e.target.value })
              }
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
            >
              <option value="">الكل</option>
              <option value="pending">مرسلة</option>
              <option value="fail">فاشلة</option>
            </select>
          </div> */}

          {/* Type Filter */}

          {/* <div>
            <label htmlFor="type" className="block mb-0.5">
              نوع الرسالة:
            </label>
            <select
              id="type"
              value={localFilters.type}
              onChange={(e) =>
                setLocalFilters({ ...localFilters, type: e.target.value })
              }
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
            >
              <option value="">الكل</option>
              <option value="schedule-message">رسائل مجدولة</option>
              <option value="API">رسائل ال API</option>
            </select>
          </div> */}

          {/* Search Filter */}

          {/* <div>
            <label htmlFor="search" className="block mb-0.5">
              بحث الرسالة:
            </label>
            <input
              type="text"
              id="search"
              value={localFilters.Search}
              onChange={(e) =>
                setLocalFilters({ ...localFilters, Search: e.target.value })
              }
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
              placeholder="بحث..."
            />
          </div> */}

          {/* To Number Filter */}
          {/* <div>
            <label htmlFor="to_number" className="block mb-0.5">
              الرقم:
            </label>
            <input
              type="text"
              id="to_number"
              value={localFilters.to_number}
              onChange={(e) =>
                setLocalFilters({ ...localFilters, to_number: e.target.value })
              }
              className="w-full border text-slate-600 hover:border-indigo-200 appearance-none px-3.5 py-1 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
              placeholder="الرقم"
            />
          </div> */}

          {/* From Date Filter */}
          <div>
            <label htmlFor="from_date" className="block mb-0.5">
              تاريخ البدء:
            </label>
            <TimePickerComponentGrid
              initialValue={
                localFilters.from_date ? new Date(localFilters.from_date) : null
              }
              onChange={(newValue) => {
                const formattedDate =
                  newValue instanceof Date ? newValue.toISOString() : "";
                setLocalFilters((prevFilters) => ({
                  ...prevFilters,
                  from_date: formattedDate,
                }));
              }}
            />
          </div>

          {/* To Date Filter */}
          <div>
            <label htmlFor="to_date" className="block mb-0.5">
              الى نهاية تاريخ:
            </label>
            <TimePickerComponentGrid
              initialValue={
                localFilters.to_date ? new Date(localFilters.to_date) : null
              }
              onChange={(newValue) => {
                const formattedDate =
                  newValue instanceof Date ? newValue.toISOString() : "";
                setLocalFilters((prevFilters) => ({
                  ...prevFilters,
                  to_date: formattedDate,
                }));
              }}
            />
          </div>

          <div>
            <button
              onClick={applyFilters}
              disabled={loadingm}
              className={`px-4 py-1.5 flex sm:mt-6 mt-1 items-center gap-2 bg-indigo-100 rounded ${
                loadingm
                  ? "cursor-not-allowed opacity-50"
                  : "hover:bg-indigo-200"
              } transition-colors duration-200`}
            >
              {loadingm ? (
                <AiOutlineLoading3Quarters className="animate-spin" size={20} />
              ) : (
                <CiFilter size={20} />
              )}
              <span>تطبيق الفلتر</span>
            </button>
          </div>
        </div>
        {/* <div className="mt-4"></div> */}
      </div>

      <div className="grid grid-cols-1 my-2 border-1 border-gray-200 p-2 rounded-sm bg md:grid-cols-2 min-[350px]:grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <Link className="cursor-pointer" to={"/reports"}>
          <Card
            icon={<TbMessage size={30} />}
            value={Messages ? Messages.length : 0}
            label="اجمالي الرسائل"
          />
        </Link>
        <Link className="cursor-pointer" to={"/total-shcedual"}>
          <Card
            icon={<RiCalendarScheduleLine size={30} />}
            value={scheduledMessagesCount}
            label="الرسائل المجدولة"
          />
        </Link>
        <Link className="cursor-pointer" to={"/success-message"}>
          <Card
            icon={<TbMessageCheck size={30} />}
            value={successMessagesCount}
            label="الرسائل الناجحة"
          />
        </Link>
        <Link className="cursor-pointer" to={"/failure-message"}>
          <Card
            icon={<TbMessage2X size={30} />}
            value={failMessagesCount}
            label="الرسائل الفاشلة"
          />
        </Link>
      </div>
    </div>
  );
};

export default CartList;
