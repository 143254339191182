import CreateNewGroup from "./CreateNewGroup";
import CreateNewContacts from "./CreateNewContacts";
import { useState } from "react";

const GroupAdnContacts = () => {
  const [activeTab, setActiveTab] = useState<"contacts" | "groups">("groups");
  return (
    <div className="px-1 min-h-screen hide-scrollbar mx-6 my-8">
      <div className="bg-white rounded-sm md max-h-screen px-0 overflow-y-auto hide-scrollbar">
        {/* Tabs */}
        <div className="flex gap-2 justify-start bg-gray-100 p-2 rounded-lg mb-2 mt-12">
          <button
            onClick={() => setActiveTab("contacts")}
            className={`py-2 px-2 ${
              activeTab === "contacts"
                ? "bg-amber-50 rounded-md text-blue-500 font-bold"
                : "text-gray-600 font-semibold"
            }`}
          >
            انشاء جهات الاتصال
          </button>{" "}
          <button
            onClick={() => setActiveTab("groups")}
            className={`py-2 px-2 ${
              activeTab === "groups"
                ? "bg-amber-50 rounded-md text-blue-500 font-bold"
                : "text-gray-600 font-semibold"
            }`}
          >
            انشاء مجموعة
          </button>
        </div>

        {activeTab === "groups" && (
          <div>
            <CreateNewGroup />
          </div>
        )}

        {activeTab === "contacts" && <CreateNewContacts />}
      </div>
    </div>
  );
};

export default GroupAdnContacts;
